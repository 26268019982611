<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="fixtured_live_center_content" v-else>
        <div class="ftb_details_tabs_content">
            <div class="tab-content">
                <div class="tab-pane active">
                    <div class="ftb_stats_section">
                      <div class="sabc_stats_head">
                        <h2>
                            {{$getSafe(()=>$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, true).name)}}
                        </h2>
                        <h2>
                            {{$getSafe(()=>$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, false).name)}}
                        </h2>
                      </div>
                        <div class="ftb_details_header_section ftb_details_header_section_diff" id="livematchstats" v-if="homeStat.penalties || homeStat.penalty_goals || homeStat.missed_penalty_goals || homeStat.drop_goals || homeStat.conversions || homeStat.missed_conversions || homeStat.tries || homeStat.passes || homeStat.try_assists">
                            <div class="ftb_stats_section">
                                <div class="ftb_stats_list_row" v-if="homeStat.penalties">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.penalties[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.penalties_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Penalties</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.penalties_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.penalties[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.penalty_goals">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.penalty_goals[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.penalty_goals_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Penalty Goals</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.penalty_goals_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.penalty_goals[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.missed_penalty_goals">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.missed_penalty_goals[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.missed_penalty_goals_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Missed Penalty Goals</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.missed_penalty_goals_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.missed_penalty_goals[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.drop_goals">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.drop_goals[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.drop_goals_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Drop Goals</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.drop_goals_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.drop_goals[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.conversions">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.conversions[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.conversions_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Conversions</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.conversions_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.conversions[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.missed_conversions">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.missed_conversions[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.missed_conversions_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Missed Conversions</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.missed_conversions_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.missed_conversions[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.tries">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.tries[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.tries_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Tries</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.tries_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.tries[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.passes">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.passes[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.passes_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Passes</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.passes_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.passes[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.try_assists">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.try_assists[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.tackles_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Try Assists</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.tackles_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.try_assists[0].attribute_value}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ftb_stats_section" v-if="homeStat.tackles || homeStat.missed_tackles || homeStat.tackle_busts || homeStat.marker_tackles">
                        <div class="sabc_stats_head">
                            <h2>
                                Defending
                            </h2>
                        </div>
                        <div class="ftb_details_header_section ftb_details_header_section_diff" >
                            <div class="ftb_stats_section">
                                <div class="ftb_stats_list_row" v-if="homeStat.tackles">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.tackles[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.tackles_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Tackles</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.tackles_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.tackles[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.missed_tackles">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.missed_tackles[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.missed_tackles_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Missed Tackles</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.missed_tackles_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.missed_tackles[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.tackle_busts">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.tackle_busts[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.tackle_busts_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Tackles Busts</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.tackle_busts_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.tackle_busts[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.marker_tackles">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.marker_tackles[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.marker_tackles_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Marker Tackles</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.marker_tackles_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.marker_tackles[0].attribute_value}}</span>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    <div class="ftb_stats_section" v-if="homeStat.scrum_won || homeStat.scrum_lost">
                        <div class="sabc_stats_head">
                            <h2>
                                Break Down
                            </h2>
                        </div>
                        <div class="ftb_details_header_section ftb_details_header_section_diff" >
                            <div class="ftb_stats_section">
                                <div class="ftb_stats_list_row" v-if="homeStat.scrum_won">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.scrum_won[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.scrum_won_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Scrum Won</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.scrum_won_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.scrum_won[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="ftb_stats_list_row" v-if="homeStat.scrum_lost">
                                    <div class="ftb_stats_list_col">
                                        <span>{{homeStat.scrum_lost[0].attribute_value}}</span>
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:homeStat.scrum_lost_per+'%'}"
                                                 class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="ftb_stats_list_status_col">
                                        <span>Scrum Lost</span>
                                    </div>
                                    <div class="ftb_stats_list_col ftb_stats_list_col_right">
                                        <div class="ftb_stats_progress">
                                            <div :style="{width:awayStat.scrum_lost_per+'%'}" class="ftb_stats_progressbar"></div>
                                        </div>
                                        <span>{{awayStat.scrum_lost[0].attribute_value}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="statsprog_less_section" v-if="homeStat.yellow_card || homeStat.red_card">
                        <div class="statsprog_less_head">
                            <h2>
                                Discipline
                            </h2>
                        </div>
                        <div class="ftb_details_header_section ftb_details_header_section_diff" >
                            <div class="ftb_stats_section">
                                <div class="statsprog_less_row" v-if="homeStat.yellow_card">
                                    <div class="statsprog_less_col">
                                        <span>{{homeStat.yellow_card[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_less_status_col">
                                        <span>Yellow Card</span>
                                    </div>
                                    <div class="statsprog_less_col statsprog_less_right">
                                        <span>{{awayStat.yellow_card[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="statsprog_less_row" v-if="homeStat.red_card">
                                    <div class="statsprog_less_col">
                                        <span>{{homeStat.red_card[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_less_status_col">
                                        <span>Red Card</span>
                                    </div>
                                    <div class="statsprog_less_col statsprog_less_right">
                                        <span>{{awayStat.red_card[0].attribute_value}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="statsprog_full_section" v-if="homeStat.carries">
                        <div class="statsprog_full_head">
                            <h2>
                                Metres Carried
                            </h2>
                        </div>
                        <div class="ftb_details_header_section ftb_details_header_section_diff" >
                            <div class="statsprog_full_section">
                                <div class="statsprog_full_row" v-if="homeStat.carries">
                                    <div class="statsprog_full_col">
                                        <span>{{homeStat.carries[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_full_progresscol">
                                        <div :style="{width:this.homeStat.carries_per+'%'}" class="ftb_stats_progress">
                                            <div class="ftb_stats_progressbar"></div>
                                        </div>
                                        <div :style="{width:this.awayStat.carries_per+'%'}"  class="ftb_stats_progress statsprog_full_progresscol_right">
                                            <div class="ftb_stats_progressbar"></div>
                                        </div>
                                    </div>
                                    <div class="statsprog_full_col statsprog_full_right">
                                        <span>{{awayStat.carries[0].attribute_value}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="statsprog_less_section" v-if="homeStat.kick_returns || homeStat.kick_from_hand_metre || homeStat.kicks_in_gen_play">
                        <div class="statsprog_less_head">
                            <h2>
                                Kick/Pass/Run
                            </h2>
                        </div>
                        <div class="ftb_details_header_section ftb_details_header_section_diff" >
                            <div class="ftb_stats_section">
                                <div class="statsprog_less_row" v-if="homeStat.kick_returns">
                                    <div class="statsprog_less_col">
                                        <span>{{homeStat.kick_returns[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_less_status_col">
                                        <span>Kick Returns</span>
                                    </div>
                                    <div class="statsprog_less_col statsprog_less_right">
                                        <span>{{awayStat.kick_returns[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="statsprog_less_row" v-if="homeStat.kick_from_hand_metre">
                                    <div class="statsprog_less_col">
                                        <span>{{homeStat.kick_from_hand_metre[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_less_status_col">
                                        <span>Kick From Hand</span>
                                    </div>
                                    <div class="statsprog_less_col statsprog_less_right">
                                        <span>{{awayStat.kick_from_hand_metre[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="statsprog_less_row" v-if="homeStat.kicks_in_gen_play">
                                    <div class="statsprog_less_col">
                                        <span>{{homeStat.kicks_in_gen_play[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_less_status_col">
                                        <span>Kick In General Play</span>
                                    </div>
                                    <div class="statsprog_less_col statsprog_less_right">
                                        <span>{{awayStat.kicks_in_gen_play[0].attribute_value}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="statsprog_less_section" v-if="homeStat.clean_break || homeStat.attacking_kicks || homeStat.offload ">
                        <div class="statsprog_less_head">
                            <h2>
                                Attacking
                            </h2>
                        </div>
                        <div class="ftb_details_header_section ftb_details_header_section_diff" >
                            <div class="ftb_stats_section">
                                <div class="statsprog_less_row" v-if="homeStat.clean_break">
                                    <div class="statsprog_less_col">
                                        <span>{{homeStat.clean_break[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_less_status_col">
                                        <span>Clean Breaks</span>
                                    </div>
                                    <div class="statsprog_less_col statsprog_less_right">
                                        <span>{{awayStat.clean_break[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="statsprog_less_row" v-if="homeStat.attacking_kicks">
                                    <div class="statsprog_less_col">
                                        <span>{{homeStat.attacking_kicks[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_less_status_col">
                                        <span>Attacking Kicks</span>
                                    </div>
                                    <div class="statsprog_less_col statsprog_less_right">
                                        <span>{{awayStat.attacking_kicks[0].attribute_value}}</span>
                                    </div>
                                </div>
                                <div class="statsprog_less_row" v-if="homeStat.offload">
                                    <div class="statsprog_less_col">
                                        <span>{{homeStat.offload[0].attribute_value}}</span>
                                    </div>
                                    <div class="statsprog_less_status_col">
                                        <span>Offload</span>
                                    </div>
                                    <div class="statsprog_less_col statsprog_less_right">
                                        <span>{{awayStat.offload[0].attribute_value}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'MatchDetailStats',
        data() {
            return {
                homeStat: [],
                awayStat: [],
            }
        },

        serverPrefetch() {
            return this.getStat();
        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%HOME_TEAM%': this.$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, true).name,
                '%AWAY_TEAM%': this.$filterTeam(this.$store.state.rugbyMatchDetailResponse.data.competitors, false).name,
                '%DATE%' : this.$route.params.date,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_match_stats_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_match_stats_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.rugby_match_stats_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        mounted() {
            this.getStat();
        },

        methods: {
            getStat(inning) {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$RUGBY_MATCH_STAT_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        if (response.data.data.teams) {
                            response.data.data.teams.forEach((item)=> {
                                if (item.isHome) {
                                    this.homeStat = item.stats;
                                } else {
                                    this.awayStat = item.stats;
                                }
                            });
                            this.makePercantageValues();
                            console.log(this.homeStat);
                            console.log(this.awayStat);
                        }
                        this.is_data_ready = true;
                    })
            },
            makePercantageValues(){
                this.homeStat.tries_per = this.homeStat.tries ? this.getPercentage(this.homeStat.tries[0].attribute_value,this.awayStat.tries[0].attribute_value) :0;
                this.awayStat.tries_per = this.awayStat.tries ? (100 - this.homeStat.tries_per) : 0;

                this.homeStat.conversions_per = this.homeStat.conversions ? this.getPercentage(this.homeStat.conversions[0].attribute_value,this.awayStat.conversions[0].attribute_value) :0;
                this.awayStat.conversions_per = this.awayStat.conversions ? (100 - this.homeStat.conversions_per) : 0;

                this.homeStat.penalty_goals_per = (this.homeStat.penalty_goals && this.homeStat.penalty_goals[0].attribute_value != 0) ? this.getPercentage(this.homeStat.penalty_goals[0].attribute_value,this.awayStat.penalty_goals[0].attribute_value) :0;
                this.awayStat.penalty_goals_per = (this.awayStat.penalty_goals && this.awayStat.penalty_goals[0].attribute_value != 0) ? (100 - this.homeStat.penalty_goals_per) : 0;

                this.homeStat.penalties_per = (this.homeStat.penalties && this.homeStat.penalties[0].attribute_value != 0) ? this.getPercentage(this.homeStat.penalties[0].attribute_value,this.awayStat.penalties[0].attribute_value) :0;
                this.awayStat.penalties_per = (this.awayStat.penalties && this.awayStat.penalties[0].attribute_value != 0) ? (100 - this.homeStat.penalties_per) : 0;

                this.homeStat.missed_penalty_goals_per = (this.homeStat.missed_penalty_goals && this.homeStat.missed_penalty_goals[0].attribute_value != 0) ? this.getPercentage(this.homeStat.missed_penalty_goals[0].attribute_value,this.awayStat.missed_penalty_goals[0].attribute_value) :0;
                this.awayStat.missed_penalty_goals_per = (this.awayStat.missed_penalty_goals && this.awayStat.missed_penalty_goals[0].attribute_value != 0) ? (100 - this.homeStat.missed_penalty_goals_per) : 0;

                this.homeStat.drop_goals_per = this.homeStat.drop_goals ? this.getPercentage(this.homeStat.drop_goals[0].attribute_value,this.awayStat.drop_goals[0].attribute_value) :0;
                this.awayStat.drop_goals_per = this.awayStat.drop_goals ? (100 - this.homeStat.drop_goals_per) : 0;

                this.homeStat.missed_conversions_per = this.homeStat.missed_conversions ? this.getPercentage(this.homeStat.missed_conversions[0].attribute_value,this.awayStat.missed_conversions[0].attribute_value) :0;
                this.awayStat.missed_conversions_per = this.awayStat.missed_conversions ? (100 - this.homeStat.missed_conversions_per) : 0;

                this.homeStat.passes_per = this.homeStat.passes ? this.getPercentage(this.homeStat.passes[0].attribute_value,this.awayStat.passes[0].attribute_value) :0;
                this.awayStat.passes_per = this.awayStat.passes ? (100 - this.homeStat.passes_per) : 0;

                this.homeStat.try_assists_per = this.homeStat.try_assists ? this.getPercentage(this.homeStat.try_assists[0].attribute_value,this.awayStat.try_assists[0].attribute_value) :0;
                this.awayStat.try_assists_per = this.awayStat.try_assists ? (100 - this.homeStat.try_assists_per) : 0;



                // this.homeStat.passes_per = this.homeStat.passes ? this.getPercentage(this.homeStat.passes[0].attribute_value,this.awayStat.passes[0].attribute_value) :0;
                // this.awayStat.passes_per = this.awayStat.passes ? (100 - this.homeStat.passes_per) : 0;

                // this.stats.ht_runs_per = this.stats.ht_runs ? this.getPercentage(this.stats.ht_runs,this.stats.at_runs) : 0;
                // this.stats.at_runs_per = this.stats.at_runs ? (100 - this.stats.ht_runs_per) : 0;

                this.homeStat.scrum_won_per = (this.homeStat.scrum_won && this.homeStat.scrum_won[0].attribute_value != 0) ? this.getPercentage(this.homeStat.scrum_won[0].attribute_value,this.awayStat.scrum_won[0].attribute_value) :0;
                this.awayStat.scrum_won_per = (this.awayStat.scrum_won && this.awayStat.scrum_won[0].attribute_value != 0) ? (100 - this.homeStat.scrum_won_per) : 0;

                this.homeStat.scrum_lost_per = (this.homeStat.scrum_lost && this.homeStat.scrum_lost[0].attribute_value != 0) ? this.getPercentage(this.homeStat.scrum_lost[0].attribute_value,this.awayStat.scrum_lost[0].attribute_value) :0;
                this.awayStat.scrum_lost_per = (this.awayStat.scrum_lost && this.awayStat.scrum_lost[0].attribute_value != 0) ? (100 - this.homeStat.scrum_lost_per) : 0;

                // this.stats.ht_lineouts_won_per = this.stats.ht_lineouts_won ? this.getPercentage(this.stats.ht_lineouts_won,this.stats.at_lineouts_won) : 0;
                // this.stats.at_lineouts_won_per = this.stats.at_lineouts_won ? (100 - this.stats.ht_lineouts_won_per) : 0;
                // this.stats.ht_lineout_won_steal_per = this.stats.ht_lineout_won_steal ? this.getPercentage(this.stats.ht_lineout_won_steal,this.stats.at_lineout_won_steal) : 0;
                // this.stats.at_lineout_won_steal_per = this.stats.at_lineout_won_steal ? (100 - this.stats.ht_lineout_won_steal_per) : 0;

                this.homeStat.tackles_per = (this.homeStat.tackles && this.homeStat.tackles[0].attribute_value != 0) ? this.getPercentage(this.homeStat.tackles[0].attribute_value,this.awayStat.tackles[0].attribute_value) :0;
                this.awayStat.tackles_per = (this.awayStat.tackles && this.awayStat.tackles[0].attribute_value != 0) ? (100 - this.homeStat.tackles_per) : 0;

                this.homeStat.missed_tackles_per = (this.homeStat.missed_tackles && this.homeStat.missed_tackles[0].attribute_value != 0) ? this.getPercentage(this.homeStat.missed_tackles[0].attribute_value,this.awayStat.missed_tackles[0].attribute_value) :0;
                this.awayStat.missed_tackles_per = (this.awayStat.missed_tackles && this.awayStat.missed_tackles[0].attribute_value != 0) ? (100 - this.homeStat.missed_tackles_per) : 0;

                this.homeStat.marker_tackles_per = (this.homeStat.marker_tackles && this.homeStat.marker_tackles[0].attribute_value != 0) ? this.getPercentage(this.homeStat.marker_tackles[0].attribute_value,this.awayStat.marker_tackles[0].attribute_value) :0;
                this.awayStat.marker_tackles_per = (this.awayStat.marker_tackles && this.awayStat.marker_tackles[0].attribute_value != 0) ? (100 - this.homeStat.marker_tackles_per) : 0;

                this.homeStat.tackle_busts_per = (this.homeStat.tackle_busts && this.homeStat.tackle_busts[0].attribute_value != 0) ? this.getPercentage(this.homeStat.tackle_busts[0].attribute_value,this.awayStat.tackle_busts[0].attribute_value) :0;
                this.awayStat.tackle_busts_per = (this.awayStat.tackle_busts && this.awayStat.tackle_busts[0].attribute_value != 0) ? (100 - this.homeStat.tackle_busts_per) : 0;

                this.homeStat.carries_per = (this.homeStat.carries && this.homeStat.carries[0].attribute_value != 0) ? this.getPercentage(this.homeStat.carries[0].attribute_value,this.awayStat.carries[0].attribute_value) :0;
                this.awayStat.carries_per = (this.awayStat.carries && this.awayStat.carries[0].attribute_value != 0) ? (100 - this.homeStat.carries_per) : 0;

                // this.stats.ht_turnovers_won_per = this.stats.ht_turnovers_won ? this.getPercentage(this.stats.ht_turnovers_won,this.stats.at_turnovers_won) : 0;
                // this.stats.at_turnovers_won_per = this.stats.at_turnovers_won ? (100 - this.stats.ht_turnovers_won_per) : 0;
                // this.stats.ht_rucks_total_per = this.stats.ht_rucks_total ? this.getPercentage(this.stats.ht_rucks_total,this.stats.at_rucks_total) : 0;
                // this.stats.at_rucks_total_per = this.stats.at_rucks_total ? (100 - this.stats.ht_rucks_total_per) : 0;
                // this.stats.ht_rucks_won_per = this.stats.ht_rucks_won ? this.getPercentage(this.stats.ht_rucks_won,this.stats.at_rucks_won) : 0;
                // this.stats.at_rucks_won_per = this.stats.at_rucks_won ? (100 - this.stats.ht_rucks_won_per) : 0;
                // this.stats.ht_rucks_lost_per = this.stats.ht_rucks_lost ? this.getPercentage(this.stats.ht_rucks_lost,this.stats.at_rucks_lost) : 0;
                // this.stats.at_rucks_lost_per = this.stats.at_rucks_lost ? (100 - this.stats.ht_rucks_lost_per) : 0;
                // this.stats.ht_mauls_total_per = this.stats.ht_mauls_total ? this.getPercentage(this.stats.ht_mauls_total,this.stats.at_turnovers_won) : 0;
                // this.stats.at_mauls_total_per = this.stats.at_mauls_total ? (100 - this.stats.ht_mauls_total_per) : 0;
                // this.stats.ht_mauls_won_per = this.stats.ht_mauls_won ? this.getPercentage(this.stats.ht_mauls_won,this.stats.at_mauls_won) : 0;
                // this.stats.at_mauls_won_per = this.stats.at_mauls_won ? (100 - this.stats.ht_mauls_won_per) : 0;
                // this.stats.ht_mauls_lost_per = this.stats.ht_mauls_lost ? this.getPercentage(this.stats.ht_mauls_lost,this.stats.at_mauls_lost) : 0;
                // this.stats.at_mauls_lost_per = this.stats.at_mauls_lost ? (100 - this.stats.ht_mauls_lost_per) : 0;
                // this.stats.ht_mauls_won_penalty_per = this.stats.ht_mauls_won_penalty ? this.getPercentage(this.stats.ht_mauls_won_penalty,this.stats.at_mauls_won_penalty) : 0;
                // this.stats.at_mauls_won_penalty_per = this.stats.at_mauls_won_penalty ? (100 - this.stats.ht_mauls_won_penalty_per) : 0;
                // this.stats.ht_mauls_won_try_per = this.stats.ht_mauls_won_try ? this.getPercentage(this.stats.ht_mauls_won_try,this.stats.at_mauls_won_try) : 0;
                // this.stats.at_mauls_won_try_per = this.stats.at_mauls_won_try ? (100 - this.stats.ht_mauls_won_try_per) : 0;
                },

            getPercentage(ht_value,at_value){
                if(ht_value == 0) {
                    return 0;
                } else {
                    ht_value = parseInt(ht_value);
                    at_value = parseInt(at_value);
                    return ((ht_value*100)/(ht_value+at_value)).toFixed(2);
                }
            }
        }
    }

</script>
